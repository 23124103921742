.Finder {
  text-align: center;
}

.app-header {
  background-color: #f8f8f8;
  padding: 30px 0;
  text-align: center;
}

.logo {
  width: 80px;
  height: 100px;
  margin: 0 auto;
  display: block;
}

.title {
  font-size: 2.5rem;
  margin: 20px 0;
}

.description {
  font-size: 1.2rem;
  color: #888;
  margin-bottom: 40px;
}

.cta-button {
  padding: 12px 24px;
  background-color: #ffbd4a;
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #ffad1f;
}

.map {
  height: 300px;
   width: 500px;
   text-align: center;
   margin:auto;
}


.map-container {
  max-width: 100%;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.blog-section {
  padding: 80px 0;
  background-color: #fff;
  text-align: center;
}

.response-section {
  padding: 20px;
  background-color: #fff;
  text-align: center;
}

.contact-section {
  padding: 30px;
  background-color: #fff;
  text-align: center;
  justify-content: center;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 40px;
}

.footer {
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-text {
  font-size: 0.9rem;
  color: #888;
}

/* this is the login prompt */
.login-prompt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.login-prompt-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
  text-align: center;
  position: relative;
}

.login-prompt-content h2 {
  margin-top: 0;
}

.login-prompt-content p {
  margin-bottom: 20px;
}

.login-prompt-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #999;
}

.login-prompt-content .close:hover {
  color: #555;
}
