.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.login-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  text-align: center;
  width: 300px;
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ffbd4a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #ffad1f;
}



/* popups */
/* .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 10px;
  width: 200px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.success-popup {
  background-color: #4caf50;
  color: #ffffff;
}

.error-popup {
  background-color: #ff5722;
  color: #ffffff;
} */



.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 220px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.success-popup {
  background-color: #43cea2;
  color: #fff;
}

.error-popup {
  background-color: #ff6b6b;
  color: #fff;
}

.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.close-icon:hover {
  color: #aaa;
}
