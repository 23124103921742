.App {
  text-align: center;
}

.logo-app-header {
  background-color: #f8f8f8;
  padding: 10px;
  text-align: center;
}

.top-app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.top-left-section {
  display: flex;
  align-items: center;
}

.top-right-section ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.top-right-section ul li {
  margin-left: 20px;
}

.top-right-section ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}


/* .App {
  text-align: center;
}

.App-logo {
  width: 80px;
  height: 10px;
  margin: 0 auto;
  display: block;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
