.password-reset-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-reset-container {
  width: 400px;
  padding: 40px;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.password-reset-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

.password-reset-container form {
  display: flex;
  flex-direction: column;
}

.password-reset-container label {
  margin-bottom: 10px;
}

.password-reset-container input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.password-reset-container button {
  padding: 10px;
  background-color: #ffbd4a;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.password-reset-links {
  margin-top: 20px;
  text-align: center;
}

.password-reset-links p {
  margin-bottom: 10px;
}

.password-reset-links a {
  color: #ffad1f;
  text-decoration: none;
}

.password-reset-links a:hover {
  text-decoration: underline;
}
